import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about-container">
      <h1 className="about-header">About Me</h1>
      <p className="about-content">Hi! My name is Matthew. I'm a motivated engineer who is deeply curious about the world.</p>
      <p className="about-content">I've been obsessed with technology since I was a kid using my dad's Windows 95 PC to play <a href="https://en.wikipedia.org/wiki/Hunter_Hunted_(video_game)" target="_blank" rel="noopener noreferrer">"Hunter Hunted"</a>. I have a diverse job history ranging from construction to software engineering. I've found passion in my career and life through building using mankind's most powerful modern tool (computers).</p>
      <p className="about-content">Philosophically, I view creating software as an art form similar to writing music or creating traditional art. The work we do as engineers is a creative endeavor, and that creativity is also our greatest professional strength. I'm fortunate in that I get paid to solve problems for TR that align with my personal goals to push what's possible using tech.</p>
      <p className="about-content">Outside of computers, I love being excessively critical of movies, having discussions with people who are passionate about their hobbies, and traveling with my partner.</p>
      <p className="about-content">Feel free to connect with me on LinkedIn to talk tech/life. ;)</p>
      <p className="about-content">Thanks for reading.</p>
      <div className="about-socials">
        <p>Socials:</p>
        <ul>
          <li><a href="https://www.linkedin.com/in/matthew-yaeger/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
          <li><a href="https://github.com/MatthewYaegerTR" target="_blank" rel="noopener noreferrer">Work GitHub</a></li>
          <li><a href="https://github.com/0xMatthew" target="_blank" rel="noopener noreferrer">Personal GitHub</a></li>
        </ul>
      </div>
    </div>
  );
}

export default About;
